<template>
  <v-container class="logs-container" fluid>
    <v-row class="system-inner-row">
      <v-col cols="12" class="pa-5 synapsa-tabs">
        <h4 class="mb-2 system-h4">Export</h4>
        <v-select
          :ripple="false"
          v-model="selectedLog"
          class="logs-select"
          :items="itemsObj"
          item-value="value"
          item-text="name"
          label="Solo field"
          dense
          solo
          return-object
          @change="emitSelect()"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      selectedLog: { name: "Today", value: "today" },
      itemsObj: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "3 days ago", value: "last3days" },
      ],
    };
  },
  methods: {
    emitSelect() {
      this.$emit("selectAssigned", this.selectedLog);
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
  },
};
</script>
<style lang="scss" scoped>
.v-text-field--solo.theme--light ::v-deep {
  .v-input__slot {
    background: transparent;
    border-radius: 15px;
  }
}
.v-text-field--solo.theme--dark ::v-deep {
  .v-input__slot {
    background: hsla(0, 0%, 100%, 0.08);
    border-radius: 15px;
  }
  .theme--dark.v-list {
    background: hsla(0, 0%, 100%, 0.08);
    color: #fff;
  }
}
.theme--dark.v-list {
  background-color: #313231;
  color: #fff;
}
.synapsa-tabs {
  margin: 0;
  border-radius: 15px !important;
}
.system-inner-row {
  margin-top: 0px !important;
}
.logs-container {
  font-family: RobotoCondensedRegular, sans-serif !important;
  padding-top: 0;
  margin-top: -4px;
}
h4 {
  font-family: UbuntuBold, sans-serif;
  font-size: 18px;
}
.logs-select {
  width: 200px;
}
.backup-btn {
  border-radius: 15px;
  transition: none;
}
.backup-btn:hover {
  border-radius: 15px;
  transition: none;
  color: white !important;
}
.system-h4 {
  font-size: 14px !important;
}
</style>


